<div class="sidenav-hold">
  <ul appDropdown class="sidenav">
    <ng-container *ngFor="let item of menuItems; let i = index">
      <ng-container>
        <li class="borda-menu" appDropdownLink routerLinkActive="open" [routerLinkActiveOptions]="{exact: true}">
          <div class="lvl1">
            <a routerLink="/{{item.state}}" appDropdownToggle matRipple
              *ngIf="item.type === 'link' && item.desenvolvimento==EmDesenvolvimento">
              <mat-icon *ngIf="item.icon" class="sidenav-mat-icon">{{item.icon}}</mat-icon>
              <span class="item-name lvl1">{{item?.name| translate}}</span>
              <span fxFlex></span>
            </a>
          </div>
        </li>
      </ng-container>
      <ng-container
        *ngIf="getMenuNaoDinamico(item.code, i) && item.type !== 'separator' && (EmDesenvolvimento || !item.desenvolvimento) && item.type !== 'icon' && listamenu.indexOf(item.code) !== -1; else MenusDinamicos">
        <li class="borda-menu" appDropdownLink routerLinkActive="open" [routerLinkActiveOptions]="{exact: true}">
          <div *ngIf="!item.disabled && item.type !== 'separator' && item.type !== 'icon'" class="lvl1">
            <a routerLink="/{{item.state}}" appDropdownToggle matRipple *ngIf="item.type === 'link'">
              <mat-icon *ngIf="item.icon" class="sidenav-mat-icon">{{item.icon}}</mat-icon>
              <span class="item-name lvl1">{{ getMenu(item.code)?.menu| translate}}</span>
              <span fxFlex></span>
            </a>

            <!-- DropDown -->
            <a appDropdownToggle matRipple (click)="activarDropDown(0)"
              *ngIf="item.type === 'dropDown' && (EmDesenvolvimento || !item.desenvolvimento)">
              <mat-icon *ngIf="item.icon" class="sidenav-mat-icon">{{item.icon}}</mat-icon>
              <span class="item-name lvl1">{{getMenu(item.code)?.menu | translate}}</span>
              <span fxFlex></span>
              <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
            </a>

            <!-- LEVEL 2 -->
            <ul class="submenu lvl2" appDropdown
              *ngIf="item.type === 'dropDown' && (EmDesenvolvimento || !item.desenvolvimento)">
              <li *ngFor="let itemLvL2 of item.sub" appDropdownLink routerLinkActive="open">
                <a routerLink="{{item.state ? '/'+item.state : ''}}/{{itemLvL2.state}}" appDropdownToggle
                  *ngIf="itemLvL2.type !== 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento) && itemLvL2.name !== 'Grupo'"
                  matRipple>
                  <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
                  <span fxFlex></span>
                </a>

                <ng-container *ngFor="let grupo of gruposRegistos">
                  <a routerLink="{{item.state ? '/'+item.state : ''}}/{{itemLvL2.state}}/{{grupo.idGrupo}}"
                    appDropdownToggle
                    *ngIf="itemLvL2.type !== 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento) && itemLvL2.name == 'Grupo' && grupo.activo"
                    matRipple>
                    <span class="item-name lvl2">{{grupo.grupoP | translate}}</span>
                    <span fxFlex></span>
                  </a>
                </ng-container>

                <div *ngIf="item.code === 'ADMINI' && itemLvL2.name === 'Aplicação'">
                  <a *ngIf="itemLvL2.type === 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento)"
                    appDropdownToggle matRipple>
                    <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
                    <span fxFlex></span>
                    <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                  </a>

                  <!-- LEVEL 3 -->
                  <ul class="submenu lvl3" appDropdown
                    *ngIf="itemLvL2.type === 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento)">
                    <li *ngFor="let itemLvL3 of itemLvL2.sub" appDropdownLink routerLinkActive="open">
                      <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state}}"
                        appDropdownToggle matRipple>
                        <span class="item-name lvl3">{{itemLvL3.name | translate}}</span>
                      </a>
                    </li>
                  </ul>
                </div>

                <div
                  *ngIf="item.code === 'ADMINI' && (EmDesenvolvimento || !item.desenvolvimento) && usaMenuFacturacao  && itemLvL2.name !== 'Processos' && itemLvL2.name !== 'Artigos' && itemLvL2.name !== 'Aplicação' && itemLvL2.name !== 'Fornecedores'
                  && itemLvL2.name !== 'Entidades' && itemLvL2.name !== 'Gestor de Conteúdos' && itemLvL2.name !== 'Eventos'  && itemLvL2.name !== 'R. Humanos'">
                  <a *ngIf="itemLvL2.type === 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento) && (EmDesenvolvimento || !itemLvL2.desenvolvimento)"
                    appDropdownToggle matRipple>
                    <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
                    <span fxFlex></span>
                    <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                  </a>

                  <!-- LEVEL 3 -->
                  <ul class="submenu lvl3" appDropdown
                    *ngIf="itemLvL2.type === 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento)">
                    <li *ngFor="let itemLvL3 of itemLvL2.sub" appDropdownLink routerLinkActive="open">
                      <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state}}"
                        appDropdownToggle matRipple>
                        <span class="item-name lvl3">{{itemLvL3.name | translate}}</span>
                      </a>
                    </li>
                  </ul>
                </div>

                <div
                  *ngIf="item.code === 'ADMINI' && (EmDesenvolvimento || !item.desenvolvimento) && usaMenuArtigo  && itemLvL2.name === 'Artigos'">
                  <a *ngIf="itemLvL2.type === 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento)"
                    appDropdownToggle matRipple>
                    <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
                    <span fxFlex></span>
                    <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                  </a>

                  <!-- LEVEL 3 -->
                  <ul class="submenu lvl3" appDropdown
                    *ngIf="itemLvL2.type === 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento)">
                    <li *ngFor="let itemLvL3 of itemLvL2.sub" appDropdownLink routerLinkActive="open">
                      <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state}}"
                        appDropdownToggle matRipple *ngIf="(EmDesenvolvimento || !itemLvL3.desenvolvimento)">
                        <span class="item-name lvl3">{{itemLvL3.name | translate}}</span>
                      </a>
                    </li>
                  </ul>
                </div>

                <div *ngIf="item.code === 'ADMINI' && usaMenuEvento && itemLvL2.name === 'Eventos'">
                  <a *ngIf="itemLvL2.type === 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento)"
                    appDropdownToggle matRipple>
                    <span class="item-name lvl2">{{itemLvL2.name| translate}}</span>
                    <span fxFlex></span>
                    <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                  </a>

                  <!-- LEVEL 3 -->
                  <ul class="submenu lvl3" appDropdown
                    *ngIf="itemLvL2.type === 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento)">
                    <li *ngFor="let itemLvL3 of itemLvL2.sub" appDropdownLink routerLinkActive="open">
                      <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state}}"
                        appDropdownToggle matRipple *ngIf="(EmDesenvolvimento || !itemLvL3.desenvolvimento)">
                        <span class="item-name lvl3">{{itemLvL3.name| translate}}</span>
                      </a>
                    </li>
                  </ul>
                </div>

                <div *ngIf="item.code === 'ADMINI' && usaMenuCliente && itemLvL2.name === 'Entidades'">
                  <a *ngIf="itemLvL2.type === 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento)"
                    appDropdownToggle matRipple>
                    <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
                    <span fxFlex></span>
                    <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                  </a>

                  <!-- LEVEL 3 -->
                  <ul class="submenu lvl3" appDropdown *ngIf="itemLvL2.type === 'dropDown'">
                    <li *ngFor="let itemLvL3 of itemLvL2.sub" appDropdownLink routerLinkActive="open">
                      <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state}}"
                        appDropdownToggle matRipple *ngIf="(EmDesenvolvimento || !itemLvL3.desenvolvimento)">
                        <span class="item-name lvl3">{{itemLvL3.name | translate}}</span>
                      </a>
                    </li>
                  </ul>
                </div>

                <div
                  *ngIf="item.code === 'ADMINI' && usaMenuCMS && itemLvL2.name === 'Gestor de Conteúdos' && (EmDesenvolvimento || !itemLvL2.desenvolvimento)">
                  <a *ngIf="itemLvL2.type === 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento)"
                    appDropdownToggle matRipple>
                    <span class="item-name lvl2">{{itemLvL2.name| translate}}</span>
                    <span fxFlex></span>
                    <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                  </a>

                  <!-- LEVEL 3 -->
                  <ul class="submenu lvl3" appDropdown
                    *ngIf="itemLvL2.type === 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento)">
                    <li *ngFor="let itemLvL3 of itemLvL2.sub" appDropdownLink routerLinkActive="open">
                      <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state}}"
                        appDropdownToggle matRipple *ngIf="(EmDesenvolvimento || !itemLvL3.desenvolvimento)">
                        <span class="item-name lvl3">{{itemLvL3.name | translate}}</span>
                      </a>
                    </li>
                  </ul>
                </div>

                <div *ngIf="item.code === 'ADMINI' && usaMenuRecursosHumanos && itemLvL2.name === 'R. Humanos'">
                  <a *ngIf="itemLvL2.type === 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento)"
                    appDropdownToggle matRipple>
                    <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
                    <span fxFlex></span>
                    <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                  </a>

                  <!-- LEVEL 3 -->
                  <ul class="submenu lvl3" appDropdown
                    *ngIf="itemLvL2.type === 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento)">
                    <li *ngFor="let itemLvL3 of itemLvL2.sub" appDropdownLink routerLinkActive="open">
                      <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state}}"
                        appDropdownToggle matRipple>
                        <span class="item-name lvl3">{{itemLvL3.name | translate}}</span>
                      </a>
                    </li>
                  </ul>
                </div>

                <div *ngIf="item.code === 'ADMINI' && usaMenuProcesso  && itemLvL2.name === 'Processos'">
                  <a *ngIf="itemLvL2.type === 'dropDown'" appDropdownToggle matRipple>
                    <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
                    <span fxFlex></span>
                    <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                  </a>

                  <!-- LEVEL 3 -->
                  <ul class="submenu lvl3" appDropdown *ngIf="itemLvL2.type === 'dropDown'">
                    <li *ngFor="let itemLvL3 of itemLvL2.sub" appDropdownLink routerLinkActive="open">
                      <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state}}"
                        appDropdownToggle matRipple>
                        <span class="item-name lvl3">{{itemLvL3.name | translate}}</span>
                      </a>
                    </li>
                  </ul>
                </div>

                <div
                  *ngIf="item.code === 'GESCON' && usaMenuCMS && ConfiguracaoCMS?.usaFaqs && itemLvL2.name === 'Faqs'">
                  <a *ngIf="itemLvL2.type === 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento)"
                    appDropdownToggle matRipple>
                    <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
                    <span fxFlex></span>
                    <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                  </a>

                  <!-- LEVEL 3 -->
                  <ul class="submenu lvl3" appDropdown *ngIf="itemLvL2.type === 'dropDown'">
                    <li *ngFor="let itemLvL3 of itemLvL2.sub" appDropdownLink routerLinkActive="open">
                      <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state}}"
                        appDropdownToggle matRipple>
                        <span class="item-name lvl3">{{itemLvL3.name | translate}}</span>
                      </a>
                    </li>
                  </ul>
                </div>


                <ng-container *ngIf="itemLvL2.type === 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento) && item.code !== 'ADMINI' && itemLvL2.name !== 'Financeira' && itemLvL2.name !== 'Faqs'
                && itemLvL2.name !== 'Artigos' && itemLvL2.name !== 'Aplicação' && itemLvL2.name
                !== 'Aplicação' && itemLvL2.name !== 'Fornecedores' && itemLvL2.name !== 'Entidades' && getMenuNaoDinamico(item.code);
                else SubMenuEntidades">
                  <a appDropdownToggle matRipple>
                    <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
                    <span fxFlex></span>
                    <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                  </a>

                  <!-- LEVEL 3 -->
                  <ul class="submenu lvl3" appDropdown *ngIf="itemLvL2.type === 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento)  && itemLvL2.name !== 'Páginas' && itemLvL2.name !== 'Formulários' && itemLvL2.name !== 'Galerias' && itemLvL2.name !== 'Estrutura Organizativa'
                  && itemLvL2.name !== 'Módulos'">
                    <li *ngFor="let itemLvL3 of itemLvL2.sub" appDropdownLink routerLinkActive="open">
                      <a routerLink="{{item.state ? '/'+item.state : ' '}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state}}"
                        appDropdownToggle matRipple *ngIf="(EmDesenvolvimento || !itemLvL3.desenvolvimento)">
                        <span class="item-name lvl3">{{itemLvL3.name | translate}}</span>
                      </a>
                    </li>
                  </ul>

                  <!-- LEVEL 3 Menu dinâmico da Estrutura Organizativa -->
                  <ul class="submenu lvl3" appDropdown
                    *ngIf="itemLvL2.type === 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento) && itemLvL2.name === 'Estrutura Organizativa'">
                    <li *ngFor="let obj of listaEstuturasOrganizativas" appDropdownLink routerLinkActive="open">
                      <a routerLink="{{item.state ? '/'+item.state : ' '}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/estrutura-organizativa/listar/{{obj.idEstruturaOrganizativa}}"
                        appDropdownToggle matRipple>
                        <span class="item-name lvl3">{{obj.estruturaOrganizativa | translate}}</span>
                      </a>
                    </li>
                  </ul>

                  <!-- LEVEL 3 Menu dinâmico da Modulos Eventos -->
                  <ul class="submenu lvl3" appDropdown
                    *ngIf="itemLvL2.type === 'dropDown' && itemLvL2.name === 'Módulos'">
                    <li *ngFor="let obj of listaModulosEventos" appDropdownLink routerLinkActive="open">
                      <a routerLink="{{item.state ? '/'+item.state : ' '}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/modulos/lista/{{obj.idModulo}}"
                        appDropdownToggle matRipple>
                        <span class="item-name lvl2">{{ obj.moduloP +" MModulo" | translate}}</span>
                      </a>
                    </li>
                  </ul>

                  <!-- LEVEL 3  Menu de formulario dinâmico -->
                  <ul class="submenu lvl3" appDropdown
                    *ngIf="itemLvL2.type === 'dropDown'  && itemLvL2.name === 'Formulários'">
                    <li *ngFor="let formulario of listaFormularios" appDropdownLink routerLinkActive="open">
                      <a routerLink="{{item.state ? '/'+item.state : ' '}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/formulario/lista/{{formulario.idFormulario}}"
                        appDropdownToggle matRipple>
                        <span class="item-name lvl3">{{formulario?.formularioP | translate}}</span>
                      </a>
                    </li>
                  </ul>

                  <!-- LEVEL 3  Menu de Galerias dinâmico -->
                  <ul class="submenu lvl3" appDropdown
                    *ngIf="itemLvL2.type === 'dropDown'  && itemLvL2.name === 'Galerias'">
                    <li *ngFor="let galeria of galerias  " appDropdownLink routerLinkActive="open">
                      <a routerLink="{{item.state ? '/'+item.state : ' '}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/galeria/{{galeria.idGaleria}}"
                        appDropdownToggle matRipple>
                        <span class="item-name lvl3">{{galeria?.galeria | translate}}</span>
                      </a>
                    </li>
                  </ul>

                  <!-- LEVEL 3  Menu Páginas dinâmicas-->
                  <ul class="submenu lvl3" appDropdown
                    *ngIf="itemLvL2.type === 'dropDown' && itemLvL2.name === 'Páginas'">
                    <li *ngFor="let pagina of paginas" appDropdownLink routerLinkActive="open">
                      <a routerLink="{{item.state ? '/'+item.state : ' '}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/pagina/lista/{{pagina.idPagina}}"
                        appDropdownToggle matRipple>
                        <span class="item-name lvl3">{{pagina.textoPagina | translate}}</span>
                      </a>
                    </li>
                  </ul>

                </ng-container>
              </li>
            </ul>
          </div>
        </li>
      </ng-container>

      <ng-template #MenusDinamicos>

        <ng-container
          *ngIf="item.code === 'FORNEC' && false && usaMenuFornecedor && listamenu.indexOf(item.code) !== -1 && listaMenuFornecedor">
          <li class="borda-menu" [class.open]="rotaActual==='fornecedores'"
            *ngFor="let fornecedor of listaMenuFornecedor; let i = index" appDropdownLink routerLinkActive="open"
            [routerLinkActiveOptions]="{exact: true}">
            <div *ngIf="!item.disabled && item.type !== 'separator' && item.type !== 'icon'" class="lvl1">
              <a *ngIf="item.type === 'dropDown'" appDropdownToggle matRipple
                (click)="activarDropDown(fornecedor.idClassificacao + 27 + i)">
                <mat-icon *ngIf="item.icon" class="sidenav-mat-icon">{{item.icon}}</mat-icon>
                <span class="item-name lvl1" [class.activo]="fornecedor.idClassificacao + 27 + i == idMenuActivo">
                  {{fornecedor.menu | translate}}</span>
                <span fxFlex></span>
                <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
              </a>
              <!-- LEVEL 2 -->
              <ul class="submenu lvl2" appDropdown *ngIf="item.type === 'dropDown'"
                [class.abrir]="fornecedor.idClassificacao + 27 + i === idMenuActivo">
                <li *ngFor="let itemLvL2 of item.sub" appDropdownLink>
                  <a routerLink="fornecedores/lista/{{fornecedor.idClassificacao}}" appDropdownToggle
                    *ngIf="itemLvL2.type !== 'dropDown'" matRipple>
                    <span class="item-name lvl2">{{fornecedor.menu | translate}}</span>
                    <span fxFlex></span>
                  </a>

                  <a *ngIf="itemLvL2.type === 'dropDown'" appDropdownToggle matRipple>
                    <span class="item-name lvl2"> * {{itemLvL2.name | translate}}</span>
                    <span fxFlex></span>
                    <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                  </a>

                  <!-- LEVEL 3 -->
                  <ul class="submenu lvl3" appDropdown *ngIf="itemLvL2.type === 'dropDown'">
                    <li *ngFor="let itemLvL3 of itemLvL2?.sub" appDropdownLink routerLinkActive="open">
                      <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state}}/{{fornecedor.idClassificacao}}"
                        appDropdownToggle matRipple>
                        <span class="item-name lvl3">
                          {{itemLvL3.name | translate}}
                        </span>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </li>
        </ng-container>

        <ng-container
          *ngIf="item.code === 'CLIENT' && (EmDesenvolvimento || !item.desenvolvimento) && listamenu.indexOf(item.code) !== -1 && listaMenuClient">
          <li class="borda-menu" [class.open]="rotaActual==='clientes'"
            *ngFor="let entidade of listaMenuClient; let i = index " appDropdownLink routerLinkActive="open"
            [routerLinkActiveOptions]="{exact: true}">
            <div *ngIf="!item.disabled && item.type !== 'separator' && item.type !== 'icon'" class="lvl1">
              <ng-container>
                <a routerLink="/clientes/lista/2" appDropdownToggle matRipple *ngIf="item.type === 'link'">
                  <mat-icon *ngIf="item.icon" class="sidenav-mat-icon">{{item.icon}}</mat-icon>
                  <span>client</span>
                  <span class="item-name lvl1"> {{entidade.menu | translate}}</span>
                  <span fxFlex></span>
                </a>


                <a routerLink="/clientes/lista/2" appDropdownToggle matRipple *ngIf="item.type === 'extLink'"
                  target="_blank">
                  <mat-icon *ngIf="item.icon" class="sidenav-mat-icon">{{item.icon}}</mat-icon>
                  <span class="item-name lvl1">{{entidade.menu| translate}}</span>
                  <span fxFlex></span>
                </a>
                <!-- DropDown -->
                <a *ngIf="item.type === 'dropDown'" matRipple (click)="activarDropDown(entidade.idClassificacao)">
                  <mat-icon *ngIf="item.icon" class="sidenav-mat-icon">{{item.icon}}</mat-icon>
                  <span class="item-name lvl1"
                    [class.activo]="entidade.idClassificacao === idMenuActivo">{{entidade.menu | translate}}</span>
                  <span fxFlex></span>
                  <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                </a>
                <!-- LEVEL 2 -->
                <ul class="submenu lvl2" appDropdown *ngIf="item.type === 'dropDown'"
                  [class.abrir]="entidade.idClassificacao == idMenuActivo">
                  <li *ngFor="let itemLvL2 of item.sub" appDropdownLink routerLinkActive="open">
                    <a routerLink="/clientes/lista/{{entidade.idClassificacao}}" appDropdownToggle
                      *ngIf="itemLvL2.type !== 'dropDown' && itemLvL2.name !== 'Acessos'" matRipple>
                      <span class="item-name lvl2">{{entidade.menu | translate}}</span>
                      <span fxFlex></span>
                    </a>
                    <div *ngIf="itemLvL2.name == 'Acessos' && getCliente(entidade.idClassificacao)?.usaLogins">
                      <a routerLink="/clientes/lista/acessos/{{entidade.idClassificacao}}" appDropdownToggle
                        *ngIf="itemLvL2.type !== 'dropDown'" matRipple>
                        <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
                        <span fxFlex></span>
                      </a>
                    </div>

                    <a *ngIf="itemLvL2.type === 'dropDown'" appDropdownToggle matRipple>
                      <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
                      <span fxFlex></span>
                      <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                    </a>

                    <!-- LEVEL 3 -->
                    <ul class="submenu lvl3" appDropdown *ngIf="itemLvL2.type === 'dropDown'">
                      <li *ngFor="let itemLvL3 of itemLvL2.sub" appDropdownLink routerLinkActive="open">
                        <div
                          *ngIf="getCliente(entidade.idClassificacao)?.idOpcTipos !== 1 && itemLvL3.name === 'Tipos'">
                          <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state + '/' + entidade.idClassificacao}}"
                            appDropdownToggle matRipple>
                            <span class="item-name lvl3">
                              {{itemLvL3.name | translate}}
                            </span>
                          </a>
                        </div>

                        <div
                          *ngIf="getCliente(entidade.idClassificacao)?.usaArquivos && itemLvL3.name === 'Tipos de Arquivos'">
                          <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state + '/' + entidade.idClassificacao}}"
                            appDropdownToggle matRipple>
                            <span class="item-name lvl3">{{itemLvL3.name | translate}}</span>
                          </a>
                        </div>

                        <div
                          *ngIf="getCliente(entidade.idClassificacao)?.idOpcTipoLocalizacao !== 1 && itemLvL3.name === 'Tipo de Localização'">
                          <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state + '/' + entidade.idClassificacao}}"
                            appDropdownToggle matRipple>
                            <span class="item-name lvl3">{{itemLvL3.name | translate}}</span>
                          </a>
                        </div>
                        <div
                          *ngIf="itemLvL3.name !== 'Tipo de Localização' && itemLvL3.name !== 'Tipos de Arquivos' && itemLvL3.name !== 'Tipos'">
                          <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state + '/' + entidade.idClassificacao}}"
                            appDropdownToggle matRipple>
                            <span class="item-name lvl3">{{itemLvL3.name | translate}}</span>
                          </a>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </ng-container>
            </div>
          </li>
        </ng-container>


        <ng-container
          *ngIf="item.code === 'ARTIGO' && (EmDesenvolvimento || !item.desenvolvimento) && usaMenuArtigo && listaMenuArtigo">
          <li class="borda-menu" [class.open]="rotaActual==='artigos'"
            *ngFor="let artigo of listaMenuArtigo; let i = index" appDropdownLink routerLinkActive="open"
            [routerLinkActiveOptions]="{exact: true}">
            <div *ngIf="!item.disabled && item.type !== 'separator' && item.type !== 'icon' " class="lvl1">
              <!-- DropDown -->
              <a *ngIf="item.type === 'dropDown'" appDropdownToggle matRipple
                (click)="activarDropDown(artigo.idClassificacao + 5 + i)">
                <mat-icon *ngIf="item.icon" class="sidenav-mat-icon">{{item.icon}}</mat-icon>
                <span class="item-name lvl1" [class.activo]="artigo.idClassificacao + 5 + i === idMenuActivo">
                  {{artigo.menu |
                  translate}}</span>
                <span fxFlex></span>
                <span class="menuitem-badge mat-bg-{{ badge.color }}" [ngStyle]="{background: badge.color}"
                  *ngFor="let badge of item.badges">{{ badge.value }}</span>
                <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
              </a>
              <!-- LEVEL 2 -->
              <ul class="submenu lvl2" appDropdown *ngIf="item.type === 'dropDown'"
                [class.abrir]="artigo.idClassificacao + 5 + i  == idMenuActivo">
                <li *ngFor="let itemLvL2 of item.sub" appDropdownLink>
                  <a routerLink="/artigos/lista/{{artigo.idClassificacao}}" appDropdownToggle
                    *ngIf="itemLvL2.type !== 'dropDown' && itemLvL2.name === 'Artigos'" matRipple>
                    <span class="item-name lvl2">{{artigo.menu | translate}}</span>
                    <span fxFlex></span>
                  </a>

                  <a routerLink="/artigos/armazens/1" appDropdownToggle
                    *ngIf="itemLvL2.type !== 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento) && getArtigo(artigo.idClassificacao)?.codNatureza=='P' && itemLvL2.name === 'Armazéns'"
                    matRipple>
                    <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
                    <span fxFlex></span>
                  </a>
                  <a *ngIf="itemLvL2.type === 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento) && getArtigo(artigo.idClassificacao)?.codNatureza==='P' && itemLvL2.name === 'Inventário'"
                    appDropdownToggle matRipple>
                    <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
                    <span fxFlex></span>
                    <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                  </a>

                  <a *ngIf="itemLvL2.type === 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento) && getArtigo(artigo.idClassificacao)?.codNatureza==='P' && itemLvL2.name === 'Stocks'; else SubMemuInventario"
                    appDropdownToggle matRipple>
                    <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
                    <span fxFlex></span>
                    <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                  </a>

                  <ng-template #SubMemuInventario>
                    <a appDropdownToggle matRipple
                      *ngIf="itemLvL2.type === 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento) && itemLvL2.name !== 'Inventário' && itemLvL2.name !== 'Stocks'">
                      <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
                      <span fxFlex></span>
                      <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                    </a>
                  </ng-template>

                  <!-- LEVEL 3 -->
                  <ul class="submenu lvl3" appDropdown
                    *ngIf="itemLvL2.type === 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento)">
                    <li *ngFor="let itemLvL3 of itemLvL2.sub" appDropdownLink routerLinkActive="open">
                      <div
                        *ngIf="getArtigo(artigo.idClassificacao)?.idOpcCategoria !== 1 && (EmDesenvolvimento || !itemLvL3.desenvolvimento) && itemLvL3.name === 'Categorias'">
                        <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state + '/' + artigo.idClassificacao}}"
                          appDropdownToggle matRipple>
                          <span class="item-name lvl3">{{itemLvL3.name | translate}}</span>
                        </a>
                      </div>

                      <div
                        *ngIf="getArtigo(artigo.idClassificacao)?.idOpcTipo !== 1 && (EmDesenvolvimento || !itemLvL3.desenvolvimento) && itemLvL3.name === 'Tipos'">
                        <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state + '/' + artigo.idClassificacao}}"
                          appDropdownToggle matRipple>
                          <span class="item-name lvl3">{{itemLvL3.name | translate}}</span>
                        </a>
                      </div>

                      <div
                        *ngIf="getArtigo(artigo.idClassificacao)?.codNatureza==='P' && (EmDesenvolvimento || !itemLvL3.desenvolvimento) && itemLvL3.name === 'Marca e Modelos'">
                        <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state + '/' + artigo.idClassificacao}}"
                          appDropdownToggle matRipple>
                          <span class="item-name lvl3">{{itemLvL3.name | translate}}</span>
                        </a>
                      </div>

                      <div
                        *ngIf="itemLvL3.name !== 'Categorias' && (EmDesenvolvimento || !itemLvL3.desenvolvimento) && itemLvL3.name !== 'Tipos' && itemLvL3.name !== 'Marca e Modelos'">
                        <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state + '/' + artigo.idClassificacao}}"
                          appDropdownToggle matRipple>
                          <span class="item-name lvl3">{{itemLvL3.name | translate}}</span>
                        </a>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </li>
        </ng-container>

        <ng-container *ngIf="item.code === 'PROCES' && listamenu.indexOf(item.code) !== -1 && listaMenuProc">
          <li class="borda-menu" [class.open]="rotaActual==='/processos/lista/' + processo.idClassificacao"
            *ngFor="let processo of listaMenuProc; let i = index" appDropdownLink routerLinkActive="open"
            [routerLinkActiveOptions]="{exact: true}">

            <div *ngIf="!item.disabled && item.type !== 'separator' && item.type !== 'icon'" class="lvl1">
              <!-- DropDown -->
              <a *ngIf="item.type === 'dropDown'" appDropdownToggle matRipple
                (click)="activarDropDown(processo?.idClassificacao + 143 + i)">
                <mat-icon *ngIf="item.icon" class="sidenav-mat-icon">{{item.icon}}</mat-icon>
                <span class="item-name lvl1" [class.activo]="(processo.idClassificacao + 143 + i) === idMenuActivo">
                  {{processo.menu | translate}}</span>
                <span fxFlex></span>
                <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
              </a>

              <a routerLink="/processos/lista/2" appDropdownToggle matRipple *ngIf="item.type === 'link'">
                <mat-icon *ngIf="item.icon" class="sidenav-mat-icon">{{item.icon}}</mat-icon>
                <span class="item-name lvl1"> {{processo.menu | translate}}</span>
                <span fxFlex></span>
              </a>

              <!-- LEVEL 2 -->
              <ul class="submenu lvl2" appDropdown *ngIf="item.type === 'dropDown'"
                [class.abrir]="(processo.idClassificacao + 143 + i) == idMenuActivo">
                <li *ngFor="let itemLvL2 of item.sub" appDropdownLink>
                  <a routerLink="/processos/lista/{{processo.idClassificacao}}" appDropdownToggle
                    *ngIf="itemLvL2.type !== 'dropDown'" matRipple>
                    <span class="item-name lvl2">{{processo.menu| translate}}</span>
                    <span fxFlex></span>
                  </a>

                  <a *ngIf="itemLvL2.type === 'dropDown'" appDropdownToggle matRipple>
                    <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
                    <span fxFlex></span>
                    <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                  </a>

                  <!-- LEVEL 3 -->
                  <ul class="submenu lvl3" appDropdown *ngIf="itemLvL2.type === 'dropDown'">
                    <li *ngFor="let itemLvL3 of itemLvL2.sub" appDropdownLink routerLinkActive="open">
                      <div
                        *ngIf="getProcessos(processo.idClassificacao)?.idOPcTipoProcesso !== 1 && itemLvL3.name === 'Tipos'">
                        <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state + '/' + processo.idClassificacao}}"
                          appDropdownToggle matRipple>
                          <span class="item-name lvl3">
                            {{getProcessos(processo.idClassificacao)?.nomeTipoP | translate}}
                          </span>
                        </a>
                      </div>

                      <div
                        *ngIf="getProcessos(processo.idClassificacao)?.idOpcCategorias !== 1 && itemLvL3.name === 'CategoriasPro'">
                        <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state + '/' + processo.idClassificacao}}"
                          appDropdownToggle matRipple>
                          <span class="item-name lvl3">
                            {{getProcessos(processo.idClassificacao)?.nomeCategoriaP}}
                          </span>
                        </a>
                      </div>

                      <div
                        *ngIf="getProcessos(processo.idClassificacao)?.usaTarefas && itemLvL3.name === 'TiposTarefa'">
                        <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state + '/' + processo.idClassificacao}}"
                          appDropdownToggle matRipple>
                          <span class="item-name lvl3">Tipo {{getProcessos(processo.idClassificacao).nomeTarefasP |
                            translate}}</span>
                        </a>
                      </div>

                      <div *ngIf="getProcessos(processo.idClassificacao)?.usaTarefas && itemLvL3.name === 'Atividades'">
                        <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state + '/' +processo.idClassificacao}}"
                          appDropdownToggle matRipple>
                          <span class="item-name lvl3">{{getProcessos(processo.idClassificacao).nomeTarefasP |
                            translate}}</span>
                        </a>
                      </div>

                      <div
                        *ngIf="itemLvL3.name !== 'Tipos' && itemLvL3.name !== 'Atividades' && itemLvL3.name !== 'CategoriasPro' && itemLvL3.name !== 'TiposTarefa'">
                        <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state + '/' + processo.idClassificacao}}"
                          appDropdownToggle matRipple>
                          <span class="item-name lvl3">{{itemLvL3.name | translate}}</span>
                        </a>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </li>
        </ng-container>

        <ng-container *ngIf="item.code === 'EVENTO' && listamenu.indexOf(item.code) !== -1 && listaMenuEvent">
          <li class="borda-menu" [class.open]="rotaActual==='eventos'"
            *ngFor="let evento of listaMenuEvent; let i = index" appDropdownLink routerLinkActive="open"
            [routerLinkActiveOptions]="{exact: true}">
            <div *ngIf="!item.disabled && item.type !== 'separator' && item.type !== 'icon'" class="lvl1">

              <a *ngIf="item.type === 'dropDown'" appDropdownToggle matRipple
                (click)="activarDropDown(evento?.idClassificacao + 19 + i)">
                <mat-icon *ngIf="item.icon" class="sidenav-mat-icon">{{item.icon}}</mat-icon>
                <span class="item-name lvl1" [class.activo]="(evento.idClassificacao + 19 + i) === idMenuActivo">
                  {{evento.menu | translate}}</span>
                <span fxFlex></span>
                <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
              </a>
              <!-- LEVEL 2 -->
              <ul class="submenu lvl2" appDropdown *ngIf="item.type === 'dropDown'"
                [class.abrir]="(evento.idClassificacao + 19 + i) === idMenuActivo">
                <li *ngFor="let itemLvL2 of item.sub" appDropdownLink>
                  <a routerLink="/{{item.state ? '/'+item.state : ''}}/{{itemLvL2.state}}/{{evento.idClassificacao}}"
                    appDropdownToggle *ngIf="itemLvL2.type !== 'dropDown' && itemLvL2.name=='Eventos'" matRipple>
                    <span class="item-name lvl2">{{evento.menu| translate}}</span>
                    <span fxFlex></span>
                  </a>
                  <a routerLink="/{{item.state ? '/'+item.state : ''}}/{{itemLvL2.state}}/{{evento.idClassificacao}}"
                    appDropdownToggle *ngIf="itemLvL2.type !== 'dropDown' && itemLvL2.name=='Cursos'" matRipple>
                    <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
                    <span fxFlex></span>
                  </a>
                  <a routerLink="/{{item.state ? '/'+item.state : ''}}/{{itemLvL2.state}}/{{evento.idClassificacao}}"
                    appDropdownToggle
                    *ngIf="Formacao(evento.idClassificacao) && itemLvL2.type !== 'dropDown' && itemLvL2.name=='Turmas'"
                    matRipple>
                    <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
                    <span fxFlex></span>
                  </a>
                  <a routerLink="/{{item.state ? '/'+item.state : ''}}/{{itemLvL2.state}}/{{evento.idClassificacao}}"
                    appDropdownToggle
                    *ngIf="usarModulo(evento.idClassificacao) && itemLvL2.type !== 'dropDown' && itemLvL2.name=='Módulos'"
                    matRipple>
                    <!--<span class="item-name lvl2">{{itemLvL2.name | translate}}</span> -->
                    <span class="item-name lvl2">{{getNomeModulo(evento.idClassificacao) | translate}}</span>
                    <span fxFlex></span>
                  </a>
                  <a routerLink="/{{item.state ? '/'+item.state : ''}}/{{itemLvL2.state}}/{{evento.idClassificacao}}"
                    appDropdownToggle
                    *ngIf="usaInscricao(evento.idClassificacao) && itemLvL2.type !== 'dropDown' && itemLvL2.name=='Inscricões'"
                    matRipple>
                    <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
                    <span fxFlex></span>
                  </a>



                  <ng-container *ngIf="itemLvL2.type === 'dropDown' && itemLvL2.name !== 'Certificados'">
                    <a *ngIf="itemLvL2.type === 'dropDown'" appDropdownToggle matRipple>
                      <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
                      <span fxFlex></span>
                      <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                    </a>

                    <!-- LEVEL 3 -->
                    <ul class="submenu lvl3" appDropdown *ngIf="itemLvL2.type === 'dropDown'">
                      <li *ngFor="let itemLvL3 of itemLvL2.sub" appDropdownLink routerLinkActive="open">
                        <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state + '/' + evento.idClassificacao}}"
                          appDropdownToggle matRipple>
                          <span class="item-name lvl3">
                            {{itemLvL3.name + evento.menu | translate}}
                          </span>
                        </a>
                      </li>
                    </ul>
                  </ng-container>

                  <ng-container *ngIf="itemLvL2.type === 'dropDown' && itemLvL2.name === 'Certificados' && opcaoCertificado(evento.idClassificacao)">
                    <a appDropdownToggle matRipple>
                      <span class="item-name lvl2">{{itemLvL2.name| translate}}</span>
                      <span fxFlex></span>
                      <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                    </a>

                    <!-- LEVEL 3 -->
                    <ul class="submenu lvl3" appDropdown *ngIf="itemLvL2.type === 'dropDown'">
                      <li *ngFor="let itemLvL3 of itemLvL2.sub" appDropdownLink routerLinkActive="open">
                        <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state + '/' + evento.idClassificacao}}"
                          appDropdownToggle matRipple>
                          <span class="item-name lvl3">
                            {{itemLvL3.name | translate}}
                          </span>
                        </a>
                      </li>
                    </ul>
                  </ng-container>

                </li>
              </ul>
            </div>
          </li>

        </ng-container>

      </ng-template>

    </ng-container>
    <li><br><br><br></li>
  </ul>
</div>