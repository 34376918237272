import {Injectable} from '@angular/core';
import {HttpHandler, HttpInterceptor, HttpRequest, HttpEvent} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable() export class CacheInterceptor implements HttpInterceptor  {
    constructor() {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const novaReq = req.clone({
            setHeaders: {
                'Cache-Control': 'no-cache',
                Pragma: 'no-cache',
                'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
            }
        });
       if (req.method === 'GET') {
           return next.handle(novaReq);
       } else {
           return next.handle(req);
       }
    }
}
