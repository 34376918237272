import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Aplicacao } from 'app/shared/models/aplicacao/aplicacao';
import { environment } from 'environments/environment';
import { EmailsEnvio } from 'app/shared/models/aplicacao/emailsenvio';
import { EmailsLogs } from 'app/shared/models/aplicacao/emailslogs';
import { Perfis } from 'app/shared/models/aplicacao/perfis';

const httpOptions = {
  headers: new HttpHeaders({
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  }),
};

@Injectable({
  providedIn: 'root',
})
export class AplicacaoService {
  baseUrlAplicacao: string;
InstalacaoUrl:string;
  constructor(private http: HttpClient) {
    this.baseUrlAplicacao = `${environment.apiAplicacao}`;
    this.InstalacaoUrl = `${environment.instalacao}`;
  }
  getInstalacao(nomeInstalacao:string): Observable<any> {
    let modelo ={NomeLinkInstalacao:nomeInstalacao};
    return this.http.post(this.InstalacaoUrl, modelo);
  }
  //#region  Área feita antes de 01-04-2021
  getLogotipoApp(): Observable<any> {
    return this.http.get(this.baseUrlAplicacao + 'aplicacao/logotipo', httpOptions);
  }

  getAplicacao(): Observable<Aplicacao> {
    return this.http
        .get(this.baseUrlAplicacao + 'aplicacao', httpOptions)
        .pipe();
  }

  getMenus(): Observable<any> {
    return this.http
        .get(this.baseUrlAplicacao + 'aplicacao/menus/1', httpOptions)
        .pipe();
  }

  getMoedas(): Observable<any> {
    return this.http
        .get(this.baseUrlAplicacao + 'aplicacao/moedas', httpOptions)
        .pipe();
  }

  getTiposEmpregos(): Observable<any> {
    return this.http
        .get(this.baseUrlAplicacao + 'aplicacao/TiposEmprego', httpOptions)
        .pipe();
  }

  getIdiomas(): Observable<any> {
    return this.http
        .get(this.baseUrlAplicacao + 'aplicacao/idiomas', httpOptions)
        .pipe();

  }

  getTodosIdiomas(): Observable<any> {
    return this.http
        .get(this.baseUrlAplicacao + 'aplicacao/todosIdiomas', httpOptions)
        .pipe();
  }
  getIdiomaAplicacao(): Observable<any> {
    return this.http
        .get(this.baseUrlAplicacao + 'aplicacao/idiomas/utilizadores', httpOptions)
        .pipe();
  }

  getProeficiencia(): Observable<any> {
    return this.http
        .get(this.baseUrlAplicacao + 'aplicacao/proeficienciaidiomas', httpOptions)
        .pipe();
  }

  gravarPermissoes(modelo: any) {
    return this.http
        .post(this.baseUrlAplicacao + 'permissoes', modelo, httpOptions)
        .pipe();
  }

  getPermissoes(): Observable<any> {
    return this.http.get(this.baseUrlAplicacao + 'permissoes', httpOptions);
  }


  getPermissoesTipos(): Observable<any> {
    return this.http
        .get(this.baseUrlAplicacao + 'permissoes/tipos', httpOptions)
        .pipe();
  }

  // ECARVALHO - Retirar
  getPermissoesAplicacao(idUtilizador: number): Observable<any> {
    return this.http
        .get(this.baseUrlAplicacao + 'permissoes/' + idUtilizador, httpOptions)
        .pipe();
  }

  getPermissoesUtilizador(idUtilizador: number): Observable<any> {
    return this.http
        .get(
            this.baseUrlAplicacao + 'permissoes/utilizador/' + idUtilizador,
            httpOptions
        )
        .pipe();
  }

  getPaises(): Observable<any> {
    return this.http
      .get<any>(this.baseUrlAplicacao + 'aplicacao/paises/true', httpOptions )
      .pipe();
  }
  getEstados(): Observable<any> {
    return this.http
      .get(this.baseUrlAplicacao + 'aplicacao/estados', httpOptions)
      .pipe();
  }

  getProvincias(idPais: string): Observable<any> {
    if(idPais && idPais.length>0){
    return this.http
        .get(this.baseUrlAplicacao + 'aplicacao/provincias/' + idPais, httpOptions)
        .pipe();}else return null;
  }

  getGrausAcademicos(): Observable<any> {
    
    return this.http
        .get(this.baseUrlAplicacao + 'Aplicacao/GrausAcademicos', httpOptions)
        .pipe();
  }

  getMunicipios(codPais: string, idProvincia: number): Observable<any> {
    if(codPais && codPais.length>0 && idProvincia>0){
    return this.http
        .get(
            this.baseUrlAplicacao + 'aplicacao/municipios/' + codPais + '/' + idProvincia,
            httpOptions
        )
        .pipe();}else return null;
  }

  getZonas(idMunicipio: number): Observable<any> {
    return this.http
        .get(
            this.baseUrlAplicacao + 'aplicacao/zonas/' + idMunicipio,
            httpOptions
        )
        .pipe();
  }

  getSexos(): Observable<any> {
    return this.http
        .get(this.baseUrlAplicacao + 'aplicacao/sexos', httpOptions)
        .pipe();
  }

  getEstadosCivis(): Observable<any> {
    return this.http
        .get(this.baseUrlAplicacao + 'aplicacao/estadoscivis', httpOptions)
        .pipe();
  }

  getCategoriasContactos(): Observable<any> {
    return this.http
        .get(this.baseUrlAplicacao + 'aplicacao/categoriascontactos', httpOptions)
        .pipe();
  }

  getTiposContactos(): Observable<any> {
    return this.http
        .get(this.baseUrlAplicacao + 'aplicacao/tiposcontactos', httpOptions)
        .pipe();
  }

  getOpcoesInsercao(): Observable<any> {
    return this.http
        .get(this.baseUrlAplicacao + 'aplicacao/opcoesinsercao', httpOptions)
        .pipe();
  }

  getUnidades(idTipo: number): Observable<any> {
    return this.http
        .get(this.baseUrlAplicacao + 'aplicacao/unidadesmedidas/' + idTipo, httpOptions)
        .pipe();
  }

  getEmails(
      activo: boolean,
      disponivelEmails: boolean
  ): Observable<any> {
    return this.http
        .get(
            this.baseUrlAplicacao +
            'aplicacao/emailsEnvio/' +
            activo +
            '/' +
            disponivelEmails,
            httpOptions
        )
        .pipe();
  }

  getLogs(): Observable<any> {
    return this.http
        .get(this.baseUrlAplicacao + 'aplicacao/emaillogs/', httpOptions)
        .pipe();
  }

  getLog(id: number): Observable<EmailsLogs> {
    return this.http
        .get(this.baseUrlAplicacao + 'aplicacao/emaillog/' + id, httpOptions)
        .pipe();
  }

  removerLog(modelo: any) {
    return this.http
        .post(this.baseUrlAplicacao + 'aplicacao/emaillog/remover', modelo, httpOptions)
        .pipe();
  }

  getEmail(idEmail: number): Observable<EmailsEnvio> {
    return this.http
        .get(this.baseUrlAplicacao + 'aplicacao/emailenvio/' + idEmail, httpOptions)
        .pipe();
  }

  salvarEmail(modelo: any) {
    return this.http
        .post(this.baseUrlAplicacao + 'aplicacao/emailenvio/salvar/', modelo, httpOptions)
        .pipe();
  }

  enviarEmail(modelo: any) {
    return this.http
        .post(this.baseUrlAplicacao + 'aplicacao/enviaremail', modelo, httpOptions)
        .pipe();
  }

  enviarEmailTeste(modelo: any) {
    return this.http
        .post(this.baseUrlAplicacao + 'aplicacao/testaremail', modelo, httpOptions)
        .pipe();
  }

  editarAplicacao(modelo: any): Observable<any>  {
    return this.http
        .post(this.baseUrlAplicacao + 'aplicacao/editarAplicacao/', modelo, httpOptions)
        .pipe();
  }

  getAppIcones(): Observable<any> {
    return this.http
        .get(this.baseUrlAplicacao + 'aplicacao/icones', httpOptions)
        .pipe();
  }
//#endregion
//#region  Ária de Permissões do Perfil

  getPerfis(): Observable<any> {
    return this.http
        .get(this.baseUrlAplicacao + 'permissoes/perfil', httpOptions)
        .pipe();
  }
  getPerfil(id: number): Observable<any> {
    return this.http
        .get(this.baseUrlAplicacao + 'permissoes/perfil/' + id, httpOptions)
        .pipe();
  }
  getPerfisPermissoes(): Observable<any> {
    return this.http
        .get(this.baseUrlAplicacao + 'permissoes/perfil/permissoes', httpOptions)
        .pipe();
  }
  getPerfilPermissoes(id: number): Observable<any> {
    return this.http
        .get(this.baseUrlAplicacao + 'permissoes/perfil/permissoes/' + id, httpOptions)
        .pipe();
  }
  getPerfilUtilizador(id: number): Observable<any> {
    return this.http
        .get(this.baseUrlAplicacao + 'permissoes/perfil/utilizador/' + id, httpOptions)
        .pipe();
  }
  gravarPerfil(modelo: any): Observable<any> {
    return this.http
        .post(this.baseUrlAplicacao + 'permissoes/perfil', modelo, httpOptions)
        .pipe();
  }
  eliminarPerfil(idPerfil: number): Observable<Perfis> {
    return this.http
        .post(this.baseUrlAplicacao + 'permissoes/perfil/eliminar', idPerfil, httpOptions)
        .pipe();
  }
  eliminarPermissaoPerfil(idPerfilPermissao: number): Observable<Perfis> {
    return this.http
        .post(this.baseUrlAplicacao + 'permissoes/perfil/permissao/eliminar', idPerfilPermissao, httpOptions)
        .pipe();
  }
  gravarPerfilPermissoes(modelo: any) {
    return this.http
        .post(this.baseUrlAplicacao + 'permissoes/perfil/gravarPermissoes', modelo, httpOptions)
        .pipe();
  }
  gravarPerfilUtilizador(modelo: any): Observable<any> {
    return this.http
        .post(this.baseUrlAplicacao + 'permissoes/perfil/utilizador', modelo, httpOptions)
        .pipe();
  }
  getmetodosPagRec(): Observable<any> {
    return this.http
        .get(this.baseUrlAplicacao + 'clientes/comercial/metodosPagRec', httpOptions)
        .pipe();
  }
  getCondicoesPagamento(): Observable<any> {
    return this.http
        .get(this.baseUrlAplicacao + 'clientes/comercial/condicoespagamento', httpOptions)
        .pipe();
  }
//#endregion
}
