import { StringFloatPipe } from './../../pipes/string-float.pipe';
import { Injectable } from '@angular/core';
import {
  HttpHeaders,
  HttpClient,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { environment } from 'environments/environment';
import { Clientes } from 'app/shared/models/clientes/clientes';
import { ClientesEmails } from 'app/shared/models/clientes/clienteemails';
import { Permissoes } from 'app/shared/models/aplicacao/permissoes';

/*const httpOptions = {
  headers: new HttpHeaders({
    Authorization: 'Bearer ' + localStorage.getItem('token'),
    'Content-Type': 'application/json; charset=utf-8',
  }),
};
*/
const httpOptions = {
  headers: new HttpHeaders({
    Authorization: 'Bearer ' + localStorage.getItem('token'),
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class ClientesService {
  baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.apiAplicacao}`;
  }

  gravar(modelo: any): Observable<any> {
    return this.http
      .post(this.baseUrl + 'clientes/', modelo, httpOptions)
      .pipe();
  }
  Actualizar(modelo: any): Observable<any> {
    return this.http
      .post(this.baseUrl + 'clientes/actualizar', modelo, httpOptions);
  }

  remover(idEntidade: number, idCliente: number): Observable<any> {
    return this.http
      .post(this.baseUrl + 'clientes/remover/' + idEntidade + '/' + idCliente , httpOptions)
      .pipe();
  }

  removerVarios(modelo: any[]): Observable<any> {
    return this.http
        .post(this.baseUrl + 'clientes/removerVarios' , modelo,  httpOptions)
        .pipe();
  }

  getClientesListagem(model: any): Observable<any> {
    return this.http
      .post(this.baseUrl + 'clientes/listagem', model, httpOptions)
      .pipe();
  }


  getClientesTodasEntidades(dadosFacturacao: boolean): Observable<any> {
    return this.http
      .get(
        this.baseUrl + 'clientes/' + dadosFacturacao,
        httpOptions
      )
      .pipe(catchError(null));
  }

  getTiposEntidadesClientes() {
    return this.http.get(this.baseUrl + 'clientes/entidades', httpOptions);
  }

  getEntidadesClientes() {
    return this.http.get(this.baseUrl + 'clientes/entidades', httpOptions);
  }

  getCliente(idCliente: number): Observable<any> {
    return this.http
      .get(this.baseUrl + 'clientes/' + idCliente, httpOptions)
      .pipe();
  }

  getTiposEntidadesCliente(idEntidade: number): Observable<any> {
    return this.http
        .get(this.baseUrl + 'clientesTipos/entidade/' + idEntidade, httpOptions)
        .pipe();
  }


  getClientePeloCodigo(codigo: string): Observable<Clientes> {
    return this.http
      .get(this.baseUrl + 'clientes/verificarPeloCodigo/' + codigo, httpOptions)
      .pipe(catchError(this.handleError));
  }

  getEmailsManuais(idEntidade: number): Observable<ClientesEmails[]> {
    return this.http
      .get(this.baseUrl + 'clientes/emails/emailsManuais/' + idEntidade, httpOptions)
      .pipe(catchError(null));
  }

  /*Permissoes*/
  gravarPermissoes(modelo: any) {
    return this.http
      .post(this.baseUrl + 'clientesPermissoes', modelo, httpOptions)
      .pipe(catchError(null));
  }

  getPermissoesClientes(idUtilizador: number): Observable<Permissoes[]> {
    return this.http
      .get(this.baseUrl + 'clientesPermissoes/' + idUtilizador, httpOptions)
      .pipe(catchError(null));
  }

  getPersonalidadesJuridicas(idEntidade: number): Observable<any> {
    return this.http
      .get(this.baseUrl + 'entidades/personalidadesJuridicas/' + idEntidade, httpOptions)
      .pipe(catchError(null));
  }

  getTodasPersonalidadesJuridicas(): Observable<any> {
    return this.http
      .get(this.baseUrl + 'entidades/personalidadesJuridicas', httpOptions)
      .pipe(catchError(null));
  }

  /*emails automaticos*/
  gravarEmailsAutomatico(modelo: any): Observable<any>  {
    return this.http
      .post(this.baseUrl + 'clientes/emailsautomaticos/', modelo, httpOptions)
      .pipe(catchError(this.handleError));
  }

  getEmailsAutomaticos(
    idEntidade: number
  ): Observable<any> {
    return this.http
      .get(
        this.baseUrl + 'clientes/emailsautomaticos/' + idEntidade,
        httpOptions
      )
      .pipe();
  }

  removerEmailsAutomatico(modelo: any): Observable<any> {
    return this.http
      .post(
        this.baseUrl + 'clientes/emailsautomaticos/remover/',
        modelo,
        httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getEmailsAutomatico(
    idEmailAutomatico: number,
    idEntidade: number
  ): Observable<any> {
    return this.http
      .get(
        this.baseUrl +
        'clientes/emailsautomaticos/' +
        idEmailAutomatico +
        '/' +
        idEntidade,
        httpOptions
      )
      .pipe(catchError(null));
  }

  getRelatorioPessoasXML(dataInicio?: any, dataFim?: any): Observable<any> {
    return this.http.get(this.baseUrl + 'clientesRelatorios/pessoasXml/' + dataInicio, httpOptions);
   // return this.http.get(this.baseUrl + 'clientesRelatorios/pessoasXml/' + dataInicio + '/' + dataFim, httpOptions);
  }

  getTiposDadosCaracteristica(): Observable<any> {
    return this.http.get(this.baseUrl + 'clientes/caracteristicas/tiposDados', httpOptions);
  }

  getExcelParaJson(ficheiroExcel): Observable<any>{
    return this.http.post(this.baseUrl + 'clientes/caracteristicas/carregarDados', ficheiroExcel, httpOptions);
  }

  getPermissoesCLI(filtro: any){
    return this.http.post(this.baseUrl + 'clientesPermissoes/obter', filtro);
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
