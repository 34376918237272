import {isDevMode} from '@angular/core';
 export const urlCliente = localStorage.getItem('clienteUrl');
export const urlInstalacoes = 'https://instalacoes-back.kiami.ao/api/v1/';
// export const urlCliente = 'https://appdemo.kiami.ao/';
// export const urlCliente = 'https://app-back.kiami.ao/';
//  export const urlCliente = 'https://appdemo.kiami.ao/';
// export const urlCliente = 'https://kiami-back.kiami.ao/';
// export const urlCliente = 'https://kiamisoft-back.kiami.ao/';
// Conectivas
// export const urlCliente = 'https://kiami-back.conectiva.pt/';
// Recredit
// export const urlCliente = "https://recredit-back.kiami.ao/";
// export const urlCliente = 'https://srr-back.kiami.ao/';

// AAMGA
// export const urlCliente = "https://aamga-back.kiami.ao/";

// **** INSTALAÇÕES QUE SÓ USAM GESTOR DE CONTEUDOS **** //
// const urlCliente = 'https://ensa-back.kiami.ao/';
// ASSOCIAÇÃO DE ACTUÁRIOS DE ANGOLA
// export const urlCliente = "https://kiami-back.actuariosangola.org/";
// export const urlCliente = 'https://curiositychoice-back.kiami.ao/';

// ARC-CONSULTORIA
//  export const urlCliente = 'https://arc-consultoria-back.kiami.ao/';
// export const urlCliente = "https://kiami-back.claramelo.com/";
// FAN
// export const urlCliente = "https://kiami-back.fanatacao.ao/";
// export const urlCliente = 'https://ocpca-back.kiami.ao/';

const env = {
  instalacao: urlInstalacoes + 'instalacoes/instalacao/',
 // instalacao: urlCliente + "apl/api/v1/",
  // aplicacao: "http://localhost:5000/api/v1/",
  // aplicacao: "https://localhost:44332/api/v1/",
 aplicacao: urlCliente + 'apl/api/v1/',

  // artigos: "https://localhost:44332/api/v1/",
  artigos: urlCliente + 'art/api/v1/',

  // financas: 'https://localhost:44332/api/v1/',
  financas: urlCliente + 'fin/api/v1/',

  //  processos: "https://localhost:44332/api/v1/",
  processos: urlCliente + 'pro/api/v1/',

  // eventos: 'https://localhost:44332/api/v1/',
  // eventos: 'https://localhost:44332/api/v1/',
  eventos: urlCliente + 'eve/api/v1/',

  //  cmsUrl: "https://localhost:44332/api/v1/",
  cmsUrl: urlCliente + 'cms/api/v1/',

  // rHumanos: "https://localhost:44332/api/v1/",
  rHumanos: urlCliente + 'rhu/api/v1/',
};

export const environment = {
  production: false,
  isDevMode: isDevMode(),
  instalacao: env.instalacao,
  // apiAplicacao:'http://localhost:5000/api/v1/',
 apiAplicacao: env.aplicacao,

  // apiArtigos: 'https://localhost:44332/api/v1/',
  apiArtigos: env.artigos,

  // apiFinancas: 'https://localhost:44332/api/v1/
  apiFinancas: env.financas,

  // apiProcessos: 'https://localhost:44332/api/v1/',
  apiProcessos: env.processos,

  //apiEventos:  'https://localhost:44332/api/v1/',
  apiEventos: env.eventos,

  //apiRHumanos: 'https://localhost:44318/api/v1/',
  apiRHumanos: env.rHumanos,

  // cmsUrl: urlCliente + 'rhu/api/v1/',
  cmsUrl: env.cmsUrl,

  enableDebug: true,
};
