<div class="p-1 relative font-weight-bold" *ngIf="mostrarAviso" style="background:#f78c8c!important; color:#000000;">
  <div class="flex-x-btw flex-y-center">
    <p class="mb-0 px-1 text-center" fxFill>
      A sua licença expira em {{diasEmFalta}} dias!
    </p>
    <div (click)="desactivarAviso()" style="height: 30px; width: 30px; position: absolute; top: 17%; right: 15px; left: auto; cursor: pointer">
      <mat-icon>close</mat-icon>
    </div>
  </div>
</div>
<mat-toolbar class="topbar mat-bg-card">
  <!-- Sidenav toggle button -->
  <button
    *ngIf="layoutConf.sidebarStyle !== 'compact'"
    mat-icon-button
    id="sidenavToggle"
    (click)="toggleSidenav()"
    matTooltip="Toggle Hide/Open"
  >
    <mat-icon>menu</mat-icon>
  </button>

  <span fxFlex></span>
  <!-- Language Switcher -->
  <button mat-button [matMenuTriggerFor]="menu">
  </button>
<mat-menu #menu="matMenu">
</mat-menu>

  <!-- Open "views/search-view/result-page.component" to understand how to subscribe to input field value -->

  <!-- Top left user menu -->

  <div
          [matMenuTriggerFor]="accountMenu"
          class="user-profile-settings"
          style="display: flex"
  >
    <button  id="profile-avatar"  mat-icon-button  class="topbar-button-right img-button pr-0 mr-0" style="margin-right: 0 !important; overflow: hidden"
    >
      <img
              [src]="this.fotoPerfil | safe: 'resourceUrl'"
              alt=""
              style="width: 100% !important; height: auto !important"
      />
    </button>
    <button mat-button [disableRipple]="true" class="topbar-button-right pl-0">
      <span>{{loginService.decodedToken?.unique_name}}</span>
    </button>
  </div>


  <mat-menu #accountMenu="matMenu">
    <button mat-menu-item [routerLink]="['/perfil/',loginService.decodedToken?.nameid]">
      <mat-icon>account_box</mat-icon>
      <span>Perfil</span>
    </button>

    <button mat-menu-item (click)="isCustomizerOpen = true" >
      <mat-icon>color_lens</mat-icon>
      <span>Aparência</span>
    </button>
    <button mat-menu-item (click)="logout()">
      <mat-icon>exit_to_app</mat-icon>
      <span>Sair</span>
    </button>
  </mat-menu>
</mat-toolbar>


<app-customizer [isCustomizerOpen]="isCustomizerOpen" (fechar)="fecharCustomizer($event)"></app-customizer>
