import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { from, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Eventos } from 'app/shared/models/eventos/eventos.model';
import { EventosClassificacoes } from 'app/shared/models/eventos/eventos-classificacoes.model'
import { EventosEstados } from 'app/shared/models/eventos/eventos-estados.model';
import { EventosTipos } from 'app/shared/models/eventos/eventos-tipos.model';
import { Estados } from 'app/shared/models/financas/documentos/estados';

const httpOptions =
{
  headers: new HttpHeaders({
    Authorization: 'Bearer ' + localStorage.getItem('token'),
    'Content-Type': 'application/json',
  }),
}

@Injectable({
  providedIn: 'root'
})
export class EventosService {
  baseUrlEvento: string;

  constructor(private http: HttpClient) {
    this.baseUrlEvento = `${environment.apiEventos}`;
  }

  //----------------------------------SECÇÃO DOS Eventos----------------------------------------------
  getEventos(modelo:any): Observable<any> {
    return this.http.post(this.baseUrlEvento+'eventos/classificacao/', modelo);
  }
  getEvento(idEvento:number, codIdioma:any) : Observable<any>{
    return this.http.get(this.baseUrlEvento + 'eventos/'+idEvento+'/'+codIdioma)
  }
  gravarEvento(modelo: any) : Observable<any>{
    console.log("mostrar modelo:", modelo)
    return this.http.post(this.baseUrlEvento + 'eventos', modelo);
  }
  deletarEvento(idEvento) : Observable<any>{
    return this.http.post(this.baseUrlEvento+'eventos/'+idEvento, "");
  }
  getProximoCodigo(modelo:any) : Observable<any>{
    return this.http.post(this.baseUrlEvento + 'eventos/ProximoCodigo', modelo)
  }

  // ------------- SECÇÃO DOS Estados dos Eventos ----------------

  getListEstados(): Observable<EventosEstados> {
    return this.http.get(this.baseUrlEvento + 'estados',);
  }
  getEventosEstados(idClassificacao: number): Observable<any> {
    return this.http.get(this.baseUrlEvento + 'estados/classificacao/' + idClassificacao,);
  }
  getDetalheEstado(idEstadoEvento) : Observable<any>{
    return this.http.get(this.baseUrlEvento + 'estados/' + idEstadoEvento);
  }
  getEstados(idClassificacao: number, codIdioma: string): Observable<any> {
    return this.http.get(this.baseUrlEvento + 'estados/classificacao/'+idClassificacao+'/'+ codIdioma);
  }
  gravarEstadosEventos(modelo): Observable<any> {
    return this.http.post(this.baseUrlEvento + 'estados/', modelo, httpOptions);
  }
  deletarEstados(idEstado): Observable<Object> {
    return this.http.post(this.baseUrlEvento + 'estados/'+idEstado, {});
  }
  //--------Fim Secção -------------



  //------------------SECÇÃO das Classsificações de Eventos ou Parametrização--------------
  getClassificacoes(codIdioma: any): Observable<any> {
    return this.http.get(this.baseUrlEvento + 'classificacoes/'+codIdioma);
  }
  getClassificacao(idClassificacao: number, codIdioma: any): Observable<any> {
    return this.http.get(this.baseUrlEvento + 'classificacoes/'+idClassificacao+'/'+ codIdioma);
  }
  getNaturezas(): Observable<EventosClassificacoes> {
    return this.http.get(this.baseUrlEvento + 'classificacoes/Naturezas');
  }
  gravarClassificacao(modelo: any) {
    return this.http.post(this.baseUrlEvento + 'classificacoes', modelo, httpOptions);
  }
  deletarClassificacao(idClassificacao: number) {
    return this.http.post(this.baseUrlEvento + 'classificacoes/'+idClassificacao, "");
  }
  listarEntidades(): Observable<any> {
    return this.http.get(this.baseUrlEvento + 'classificacoes/Entidades');
  }
  //--------Fim Secção -------------




  // -----------------------SECÇÃO DOS Tipos de Eventos ---------------------
  getTiposEventos(idClassificacao: number, codIdioma: string) : Observable<any>{
    return this.http.get(this.baseUrlEvento + 'tiposEventos/classificacao/'+idClassificacao+'/'+codIdioma)
  }
  detalheTipo(idTipoEvento: number): Observable<any> {
    return this.http.get(this.baseUrlEvento + 'tiposEventos/'+idTipoEvento);
  }
  gravarTipo(modelo) : Observable<any>{
    return this.http.post(this.baseUrlEvento + 'tiposEventos/', modelo, httpOptions);
  }
  deletarTipo(idTipoEvento) : Observable<any>{
    return this.http.post(this.baseUrlEvento + 'tiposEventos/' + idTipoEvento,"");
  }

  //----------------------------Fim Secção -----------------------------------



  //---------------------Secção dos artigos-----------------------------
  getArtigos(): Observable<any> {
    return this.http.get(this.baseUrlEvento + 'artigos');
  }

  //--------------------------Fim seccao---------------------------------



  //------------ Secção Estados das inscrições-------------------

  getListEstadosInscricao(): Observable<any> {
    return this.http.get(this.baseUrlEvento+'estadosInscricoes');
  }
  mostrarEstadoInscricaoPeloId(idEstadoIncricao): Observable<any> {
    return this.http.get(this.baseUrlEvento + 'estadosInscricoes/'+idEstadoIncricao);
  }
  inserirEstadoInscricoes(modelo): Observable<any> {
    return this.http.post(this.baseUrlEvento + 'estadosInscricoes', modelo, httpOptions);
  }
  editarEstadoInscricoes(idEstadoIncricao, modelo): Observable<any> {
    return this.http.post(this.baseUrlEvento + 'estadosInscricoes/Editar/' + idEstadoIncricao, modelo);
  }
  eliminarEstadoInscricoes(idEstadoIncricao): Observable<any> {
    return this.http.post(this.baseUrlEvento + 'estadosInscricoes/' + idEstadoIncricao,"");
  }

  //--------------------------Fim Secção ---------------------------------



  // ------------------------ Secção de inscricoes-------------------------


  getListInscricao(modelo: any): Observable<any> {
    return this.http.post(this.baseUrlEvento+'inscricoes/classificacao', modelo);
  }
  mostrarInscricaoPelolId(idInscricao): Observable<any> {
    return this.http.get(this.baseUrlEvento + 'inscricoes/'+idInscricao);
  }
  inserirInscricoes(modelo:any): Observable<any> {
    return this.http.post(this.baseUrlEvento + 'inscricoes/', modelo, httpOptions);
  }
  editarInscricoes(idInscricao: Number, modelo: any): Observable<any> {
    return this.http.post(this.baseUrlEvento + 'inscricoes/Editar/' + idInscricao, modelo, httpOptions);
  }
  deletarInscricao(idInscricao) {
    return this.http.post(this.baseUrlEvento + 'inscricoes/' + idInscricao,"");
  }
  getEntidades(): Observable<any> {
    return this.http.get(this.baseUrlEvento + 'inscricoes/ListarEntidades');
  }

  //--------Fim Secção -------------


  //---------------------Secao dos tipos de realizacoes-------------------

  getTipoRealizacoes(): Observable<any> {
    return this.http.get(this.baseUrlEvento + 'tipoRealizacoes');
  }

  //-----------------------------Fim seccao--------------------------------


  //----------------------------Seccao das realizações-----------------------

  getRealizacoes(): Observable<any> {
    return this.http.get(this.baseUrlEvento + 'realizacoes');
  }
  listarRealizacoesPorClassificacao(idClassificacao: number): Observable<any> {
    return this.http.get(this.baseUrlEvento + 'realizacoes/classificacao/'+idClassificacao);
  }
  mostrarRealizacoesPeloId(IdRealizacao): Observable<any> {
    return this.http.get(this.baseUrlEvento + 'realizacoes/'+IdRealizacao);
  }
  registarRealizacoes(modelo): Observable<any> {
    return this.http.post(this.baseUrlEvento + 'realizacoes', modelo, httpOptions);
  }
  editarRealizacoes(IdRealizacao, modelo): Observable<any> {
    return this.http.post(this.baseUrlEvento + 'realizacoes/Editar/'+IdRealizacao, modelo);
  }
  eliminarRealizacoes(IdRealizacao): Observable<any> {
    return this.http.post(this.baseUrlEvento + 'realizacoes/'+IdRealizacao, "");
  }

  //------------------------------Fim seccao----------------------------------



  //------------------------------Secção dos locais----------------------------

  getLocais(modelo: any): Observable<any> {
    return this.http.post(this.baseUrlEvento + 'locais/lista', modelo);
  }

  getLocal(idLocal: number): Observable<any> {
    return this.http.get(this.baseUrlEvento + 'locais/'+idLocal);
  }

  gravarLocal(modelo: any): Observable<any> {
    return this.http.post(this.baseUrlEvento + 'locais', modelo, httpOptions);
  }

  removerLocal(idLocal: any): Observable<any> {
    return this.http.post(this.baseUrlEvento + 'locais/'+idLocal,"");
  }

  activarDesactivarLocal(idLocal: any): Observable<any> {
    return this.http.post(this.baseUrlEvento + 'locais/activacao/'+idLocal,"");
  }

  getPaises(): Observable<any> {
    return this.http.get(this.baseUrlEvento + 'locais/paises');
  }

  getProvincias(idPais: number): Observable<any> {
    return this.http.get(this.baseUrlEvento + 'locais/provincias/'+ idPais);
  }

  getMunicipios(idProvinca: number): Observable<any> {
    return this.http.get(this.baseUrlEvento + 'locais/municipios/'+ idProvinca);
  }

  getZonas(idMunicipio: number): Observable<any> {
    return this.http.get(this.baseUrlEvento + 'locais/zonas/'+ idMunicipio);
  }
  //---------------------------Fim Secção ----------------------------------



  //---------------------------Seccao dos modulos -----------------------------

  getModulo(modelo: any): Observable<any> {
    return this.http.post(this.baseUrlEvento + 'modulos/classificacao', modelo);
  }
  detalheModulo(idModulo): Observable<any> {
    return this.http.get(this.baseUrlEvento + 'modulos/'+idModulo);
  }
  gravaModulo(modelo): Observable<any> {
    return this.http.post(this.baseUrlEvento+'modulos', modelo, httpOptions);
  }
  editarModulo(modelo): Observable<any> {
    return this.http.post(this.baseUrlEvento + 'modulos/Editar/', modelo);
  }
  deletarModulo(idModulo) : Observable<any>{
    return this.http.post(this.baseUrlEvento + 'modulos/EliminarModulo/' + idModulo, "");
  }
  //---------------------------Fimm----------------------------------------

  //---------------------------Seccao dos e-mails -----------------------------

  getEmails(codIdioma: string) : Observable<any>{
    return this.http.get(this.baseUrlEvento + 'emails/'+ codIdioma);
  }

  getEmail(idEmailInscricao: number) : Observable<any>{
    return this.http.get(this.baseUrlEvento + 'emails/detalhe/'+ idEmailInscricao);
  }

  gravarEmails(modelo: any) : Observable<any>{
    return this.http.post(this.baseUrlEvento + 'emails/', modelo);
  }

  removerEmail(idEmailInscricao: number) : Observable<any>{
    return this.http.post(this.baseUrlEvento + 'emails/'+idEmailInscricao, "");
  }

  //---------------------------Seccao dos tipo de arquivos -----------------------------

  getTiposArquivos(idClassificacao: number) : Observable<any>{
    return this.http.get(this.baseUrlEvento + 'arquivosTipos/Classificacao/'+ idClassificacao);
  }
  getTipoArquivo(idArquivoTipo: number) : Observable<any>{
    return this.http.get(this.baseUrlEvento + 'arquivosTipos/'+ idArquivoTipo);
  }

  gravarTipoArquivo(modelo: any) : Observable<any>{
    return this.http.post(this.baseUrlEvento + 'arquivosTipos/', modelo);
  }

  removerTipoArquivo(idArquivoTipo: number) : Observable<any>{
    return this.http.post(this.baseUrlEvento + 'arquivosTipos/'+idArquivoTipo, "");
  }
}