import { Component, OnInit, EventEmitter, Input, ViewChildren  , Output, Renderer2 } from '@angular/core';
import { ThemeService } from '../../services/theme.service';
import { LayoutService } from '../../services/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { EgretNotifications2Component } from '../egret-notifications2/egret-notifications2.component';
import {LoginService} from '../../services/aplicacao-service/login.service';
import {UtilizadoresService} from '../../services/aplicacao-service/utilizadores.service';
import {UserDataService} from '../../services/data/user-data.service';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {JwtHelperService} from '@auth0/angular-jwt';
import {LocalStoreService} from '../../services/local-store.service';
import {Utilizadores} from '../../models/aplicacao/utilizadores';

@Component({
  selector: 'app-header-side',
  templateUrl: './header-side.template.html'
})
export class HeaderSideComponent implements OnInit {
  @Input() notificPanel;
  @Input() isCustomizerOpen: boolean;
  @ViewChildren(EgretNotifications2Component) noti;
  public egretThemes;
  public layoutConf: any;
  fotoPerfil: string;
  fotografia?: string;
  user: Utilizadores;
  jwtHelper = new JwtHelperService();
  decodedToken: any;
  subscricoes: Subscription[] = [];
  mostrarAviso = false;
  diasEmFalta = localStorage.getItem('diasEmFalta');
  constructor(
    private themeService: ThemeService,
    private layout: LayoutService,
    public translate: TranslateService,
    public loginService: LoginService,
    public localStore: LocalStoreService,
    private utilizadoresService: UtilizadoresService,
    private renderer: Renderer2,
    private data: UserDataService,
    private rota: Router,
  ) {}
  ngOnInit() {
    this.egretThemes = this.themeService.egretThemes;
    this.layoutConf = this.layout.layoutConf;
    const token = localStorage.getItem('token');
    this.decodedToken = this.jwtHelper.decodeToken(token);
    this.getUserFromLocalStorage();
    this.loginService.decodedToken = this.decodedToken;
    if (localStorage.getItem('app89B8B8E486421463D7E0F5mostrarAviso')){
      this.mostrarAviso = true;
    }
  }

  desactivarAviso() {
    localStorage.removeItem('app89B8B8E486421463D7E0F5mostrarAviso');
    this.mostrarAviso = false;
  }

  getUserFromLocalStorage() {
    if (this.localStore.getItem('_utilizador')) {
      this.user = this.localStore.getItem('_utilizador');
      this.fotografia = this.user.fotografia;
      this.fotoPerfil = this.user.fotografia;
    } else {
      this.getImagemUtilizador(this.decodedToken.nameid);
    }
  }

  getImagemUtilizador(id: number) {
    this.subscricoes.push(
        this.utilizadoresService
            .getUtilizador(id)
            .subscribe((res) => {
              this.fotografia = res.fotografia;
              this.data.setFotoPerfil(res.fotografia);
              this.subscricoes.push(
                  this.data.getFotoPerfil().subscribe((foto) => {
                    this.fotoPerfil = foto;
                  })
              );
            })
    );
  }
  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('artigos');
    localStorage.removeItem('processos');
    localStorage.removeItem('financas');
    this.rota.navigate(['sessoes/login']);
  }

  changeTheme(theme) {
    // this.themeService.changeTheme(theme);
  }
  toggleNotific() {
    this.notificPanel.toggle();
  }
  toggleSidenav() {
    if (this.layoutConf.sidebarStyle === 'closed') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      });
    }
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    });
  }

  toggleCollapse() {
    // compact --> full
    if (this.layoutConf.sidebarStyle === 'compact') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full',
        sidebarCompactToggle: false
      }, {transitionClass: true});
    }

    // * --> compact
    this.layout.publishLayoutChange({
      sidebarStyle: 'compact',
      sidebarCompactToggle: true
    }, {transitionClass: true});

  }

  fecharCustomizer(event: any) {
    this.isCustomizerOpen = event;
  }

  onSearch(e) {
    //   console.log(e)
  }
}
